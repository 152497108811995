import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import truncate from 'lodash/truncate';
import styled from 'styled-components';
import Box from '../../Atoms/Box';
import FlexBox from '../../Atoms/FlexBox';
import PreviewCompatibleImage from '../../Atoms/PreviewCompatibleImage';
import Paragraph from '../../Atoms/Paragraph';
import CustomLink from '../../Atoms/CustomLink';
import theme from '../../../styles/theme';
import Heading from '../../Atoms/Heading';
import { buildArticlePath } from '../../../utilis/helpers';

const StyledHeading = styled(Heading)`
  transition: color 100ms ease-in-out;
  color: ${props => props.theme.color.black};
  &:hover {
    color: ${props => props.theme.color.blue};
  }
`;

class ItemWithImage extends PureComponent {
  render() {
    const { thumbnail, title, date, excerpt } = this.props;

    return (
      <FlexBox
        borderBottom={`1px solid ${theme.color.midGray}`}
        width="100%"
        height={['auto', 'auto', 'auto', 'auto', 220]}
        py={3}
        alignItems="flex-start"
      >
        <Box
          width={128}
          color={theme.color.blue}
          textAlign="center"
          pr={3}
          pt={3}
          alignSelf="flex-start"
        >
          <PreviewCompatibleImage imageInfo={thumbnail} />
        </Box>
        <FlexBox flex={1} height="100%" flexDirection="column" justifyContent="flex-start">
          <Heading h={4} pb={1} color={theme.color.blue} width="100%">
            {date}
          </Heading>
          <CustomLink to={buildArticlePath(this.props)} width="100%">
            <StyledHeading h={3}>{truncate(title, { length: 90 })}</StyledHeading>
          </CustomLink>
          <Box flex={1} width="100%" position="relative" overflowHidden mb={2}>
            <Paragraph fontSize={1} position="absolute" width="100%">
              {excerpt}
            </Paragraph>
          </Box>
          <Box width="100%">
            <CustomLink to={buildArticlePath(this.props)} fontSize={1}>
              <FormattedMessage id="button:readon" defaultMessage="Read on" />
            </CustomLink>
          </Box>
        </FlexBox>
      </FlexBox>
    );
  }
}

export default ItemWithImage;
