import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import { EffectFade, Pagination } from 'swiper/dist/js/swiper.esm';
import styled from 'styled-components';

import 'swiper/dist/css/swiper.css';
import PreviewCompatibleImage from '../Atoms/PreviewCompatibleImage';
import Box from '../Atoms/Box';
import Heading from '../Atoms/Heading';
import Paragraph from '../Atoms/Paragraph';
import theme from '../../styles/theme';

const SwiperStyler = styled.div`
  .swiper-container-horizontal > .swiper-pagination {
    text-align: left;
    bottom: 10%;
    padding-left: 32px;
    padding-right: 32px;
    @media screen and (min-width: 992px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media screen and (min-width: 1200px) {
      padding-left: 128px;
      padding-right: 128px;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 1px solid ${props => props.theme.color.white};
    border-radius: 100%;
    opacity: 1;
    background: transparent;
  }

  .swiper-pagination-bullet-active {
    background: ${props => props.theme.color.white};
  }
`;

class HeroSwiper extends Component {
  render() {
    const { list } = this.props;

    const params = {
      modules: [EffectFade, Pagination],
      loop: true,
      effect: 'fade',
      autoplay: 300,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    };

    return (
      <SwiperStyler>
        <Swiper {...params}>
          {list
            && list.map(({
              img, mobileImg, title, desc, link,
            }, index) => (
              <div key={index}>
                <Box display={['none', 'none', 'none', 'block']} height={550} overflowHidden backgroundColor={theme.color.darkBlue}>
                  <PreviewCompatibleImage imageInfo={img} critical fadeIn={false} />
                </Box>
                <Box display={['block', 'block', 'block', 'none']} height={400}>
                  {mobileImg && <PreviewCompatibleImage imageInfo={mobileImg} critical fadeIn={false} />}
                </Box>
                <Box
                  position="absolute"
                  top={['30%', '30%', '30%', '40%', '40%']}
                  color={theme.color.white}
                  px={[5, 5, 5, 6, 8]}
                  maxWidth={['none', 'none', 'none', '50%']}
                >
                  <Paragraph fontSize={[3, 4, 5]} pt={[3, 3, 4]} pb={2}>{desc}<Box as="span" fontWeight={600}>{title}</Box></Paragraph>
                </Box>
              </div>
            ))}
        </Swiper>
      </SwiperStyler>
    );
  }
}

export default HeroSwiper;
