import React from 'react';
import { Link, graphql } from 'gatsby';

import { PageHelmet } from '../components/PageHelmet';
import Hero from '../components/Home/Hero';
import PlayVideos from '../components/Home/PlayVideos';
import LeftTextRightImage from '../components/SectionBlocks/LeftTextRightImage';
import NewsAndAnnouncements from '../components/Home/NewsAndAnnouncements';

// ********************************* UI Template (Shared with CMS) *********************************
export const IndexPageTemplate = ({ title, description, hero, overview, cms = false }) => (
  <div>
    <PageHelmet title={title} description={description} />
    <Hero hero={hero} />
    <LeftTextRightImage {...overview} />
    <NewsAndAnnouncements />
    <PlayVideos />
  </div>
);

// ********************************* Render page *********************************
const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <IndexPageTemplate {...frontmatter} />;
};

export default IndexPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query IndexPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        hero {
          title
          desc
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          mobileImg {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        overview {
          title
          desc
          link {
            url
            text
          }
          img {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
