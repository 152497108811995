import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import styled from 'styled-components';
import CustomLink from '../Atoms/CustomLink';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import theme from '../../styles/theme';
import { pageUrls } from '../../config/pageSetting';
import Heading from '../Atoms/Heading';
import ItemWithImage from './Items/ItemWithImage';
import ItemWithDate from './Items/ItemWithDate';

import ReadMoreButton from './ReadMoreButton';

class AnnouncementsShortRollUI extends React.Component {
  render() {
    const { edges: posts } = this.props.data.allMarkdownRemark;

    return (
      <>
        <Row multi>
          <Column col="is-12">
            <CustomLink to={pageUrls.articles_announcements.url}>
              <Heading h={2} pb={0} color={theme.color.blue}>
                <FormattedMessage
                  id="announcements:shortRoll.title"
                  defaultMessage="Latest Announcements"
                />
              </Heading>
            </CustomLink>
            {posts
              && posts.map(({ node: post }) => (
                <ItemWithImage
                  key={post.id}
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  type={post.frontmatter.type}
                  group={post.frontmatter.group}
                  thumbnail={post.frontmatter.thumbnail}
                  slug={post.fields.slug}
                  excerpt={post.excerpt}
                />
              ))}
          </Column>
        </Row>
        <Row>
          <Column>
            <CustomLink to={pageUrls.articles_announcements.url}>
              <ReadMoreButton />
            </CustomLink>
          </Column>
        </Row>
      </>
    );
  }
}

const query = graphql`
  query AnnouncementsShortRollQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "article-post" }
          group: { eq: "investor_relations" }
          type: { eq: "announcements" }
        }
      }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "DD MMMM YYYY")
            type
            group
            thumbnail {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AnnouncementsShortRoll = () => (
  <StaticQuery query={query} render={data => <AnnouncementsShortRollUI data={data} />} />
);

export default AnnouncementsShortRoll;
