import React, { Component } from 'react';
import styled from 'styled-components';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import HeroSwiper from './HeroSwiper';

const FullScreenContainer = styled(Container)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

class Hero extends Component {
  render() {
    const { hero } = this.props;

    return (
      <Section p={0}>
        <FullScreenContainer fluid>
          <HeroSwiper list={hero} />
        </FullScreenContainer>
      </Section>
    );
  }
}

export default Hero;
