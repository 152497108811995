import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import CustomLink from '../Atoms/CustomLink';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import theme from '../../styles/theme';
import ItemWithDate from './Items/ItemWithDate';
import { pageUrls } from '../../config/pageSetting';
import Heading from '../Atoms/Heading';
import ReadMoreButton from './ReadMoreButton';

class NewsShortRollUI extends React.Component {
  render() {
    const { edges: posts } = this.props.data.allMarkdownRemark;

    return (
      <>
        <Row multi>
          <Column col="is-12">
            <Heading h={2} pb={0} color={theme.color.blue}>
              <FormattedMessage id="news:shortRoll.news" defaultMessage="News" />
            </Heading>
            {posts &&
              posts.map(({ node: post }) => (
                <ItemWithDate
                  key={post.id}
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  type={post.frontmatter.type}
                  group={post.frontmatter.group}
                  slug={post.fields.slug}
                  excerpt={post.excerpt}
                />
              ))}
          </Column>
        </Row>
        <Row>
          <Column>
            <CustomLink to={pageUrls.news.url}>
              <ReadMoreButton />
            </CustomLink>
          </Column>
        </Row>
      </>
    );
  }
}

const query = graphql`
  query NewsShortRollQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "article-post" }
          group: { eq: "news" }
          type: { eq: "news" }
        }
      }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "YYYY-MMM-DD")
            type
            group
          }
        }
      }
    }
  }
`;

const NewsShortRoll = () => (
  <StaticQuery
    query={query}
    render={data => <NewsShortRollUI data={data} />}
  />
);

export default NewsShortRoll;
