import React, { Component } from 'react';
import styled from 'styled-components';

import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';
import NewsShortRoll from '../Rolls/NewsShortRoll';
import AnnouncementsShortRoll from '../Rolls/AnnouncementsShortRoll';

class NewsAndAnnouncements extends Component {
  render() {
    return (
      <Section>
        <Container>
          <Row multi>
            <Column col="is-12 is-half-widescreen">
              <AnnouncementsShortRoll />
            </Column>
            <Column>
              <NewsShortRoll />
            </Column>
          </Row>
        </Container>
      </Section>
    );
  }
}

export default NewsAndAnnouncements;
