import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import FlexBox from '../Atoms/FlexBox';
import Box from '../Atoms/Box';
import Icon from '../Atoms/Icon';
import theme from '../../styles/theme';
import Readmore from '../../assets/icons/readmore.svg';

const ViewAllWrapper = styled(Box)`
  path {
    transition: fill 100ms ease-in;
    fill: ${props => props.theme.color.blue};
  }
   &:hover {
     path {
      fill: ${props => props.theme.color.darkBlue};
     }
   }
`;

function ReadMoreButton({text}) {
  return (
    <ViewAllWrapper width={text ? 'auto' : 100}>
      <FlexBox justifyContent="flex-start">
        <Box display="inline-block">
          <Icon size={16} mr={2} color={theme.color.blue} style={{transform: 'rotate(-90deg)' }}>
            <Readmore />
          </Icon>
        </Box>
        {!text && <FormattedMessage id="button:viewAll" defaultMessage="View all" />}
        {text && text}
      </FlexBox>
    </ViewAllWrapper>
  );
}

export default ReadMoreButton;
