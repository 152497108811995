import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './videos.css';

import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import Row from '../Atoms/Row';
import Column from '../Atoms/Column';

const PlayVideos = () => {
    return (
        <div className='player-wrapper'>
        <ReactPlayer
            className='react-player'
            url= 'https://vimeo.com/723643834'
            width="96%"
            height="100%"
            controls = {true}
            playing={true}
            loop={true}
        />
        </div>
    )
}

export default PlayVideos;
